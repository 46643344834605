<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">{{ role.id ? 'Edit' : 'New' }} Role<br>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to areas & groups list"
              @click="$router.push({name: 'admin.roles.index'})"
            ><i data-feather="chevron-left" /></a>
            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Senior call settings"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div
          v-if="errors != ''"
          class="alert alert-danger mt-1 p-1"
        >
          {{ errors }}
        </div>
        <div class="row">
          <form
            id="edit-form"
            action=""
          >
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  <h3 class="cart-title">
                    <a data-action="collapse">Content</a>
                  </h3>
                  <div class="heading-elements">
                    <ul class="list-inline mb-0">
                      <li>
                        <a data-action="collapse"><i data-feather="chevron-down" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="card-content collapse show">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-8">
                        <div class="mb-1 form-group required">
                          <label
                            for=""
                            class="form-label"
                          >Name</label>
                          <input
                            v-model="role.name"
                            :disabled="roleId ? !role.can_delete : false"
                            class="form-control"
                            type="text"
                          >
                        </div>
                      </div>
                      <!-- <div class="col-sm-4">
                        <div
                          class="mb-1"
                        >
                          <label
                            for=""
                            class="form-label"
                          >Which menu will be using</label>
                          <select
                            v-model="role.menu"
                            class="form-select"
                          >
                            <option
                              selected
                              value="admin"
                            >
                              Admin
                            </option>
                            <option value="researcher">
                              Researcher
                            </option>
                          </select>
                        </div>
                      </div> -->
                    </div>
                    <!-- <div class="row">
                      <div class="col-sm-5">
                        <div class="row">
                          <div class="col-sm-4">
                            <div class="mb-1 form-group">
                              <label
                                for=""
                                class="form-label"
                              >Check all permissions</label>
                              <input
                                type="checkbox"
                                class="mx-1 form-checkbox-control"
                                @click="checkAllPermissions($event)"
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
              <div
                v-if="!loading"
                class="row"
              >
                <div class="col-12">
                  <div class="card">
                    <div class="card-header">
                      <h3 class="cart-title">
                        <a data-action="collapse">Manage permissions</a>
                      </h3>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12">
                          <input
                            v-model="filters.name"
                            class="form-control mb-1"
                            placeholder="Filter permissions here..."
                            type="text"
                          >
                        </div>
                      </div>
                      <table class="table table-stripe">
                        <thead>
                          <tr>
                            <th>Section</th>
                            <th>Permissions</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(permission, index) in filterPermissions"
                            :key="index"
                          >
                            <td class="text-capitalize">
                              {{ index }}
                            </td>
                            <td class="text-capitalize">
                              <template v-for="custom, key in permission">
                                <template v-if="role.permissions_custom && role.permissions_custom.findIndex(f => f.id === custom.id) >= 0">
                                  <span
                                    :key="key"
                                    :class="`badge ${key > 0 ? 'ms-50' : ''} badge-light-${role.permissions_custom[role.permissions_custom.findIndex(f => f.id === custom.id)].privacy == 'all' ? 'success' : 'info'}`"
                                  >
                                    {{ `${custom.name}: ${role.permissions_custom[role.permissions_custom.findIndex(f => f.id === custom.id)].privacy} ` }}
                                  </span>
                                </template>
                              </template>
                            </td>
                            <td>
                              <a
                                class="nav-link active"
                                aria-current="page"
                                @click="openOffcanva(index, permission)"
                              >
                                <i
                                  class="me-50"
                                  data-feather="edit-3"
                                /> Edit
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <ItemInfoSheet @closed="savePermissions">
                  <template #info-sheet-title>
                    Manage permissions - {{ selectedItemTitle }}
                  </template>
                  <template #info-sheet>
                    <div class="offcanvas-body">
                      <div
                        v-for="permission, index in selectedItem"
                        :key="index"
                        class="mb-2"
                      >
                        <label
                          for=""
                          class="form-label text-capitalize"
                          style="font-size: 16px"
                        >{{ permission.name }}</label>
                        <br>
                        <div
                          class="btn-group"
                          role="group"
                          aria-label="Message mentions"
                        >
                          <input
                            :id="`none-${index}`"
                            type="radio"
                            class="btn-check"
                            :name="`options-${index}`"
                            autocomplete="off"
                            :checked="!role.permissions_custom || role.permissions_custom.filter(e => {
                              return e.name == permission.name
                            }).length === 0"
                            @click="changePermission(permission)"
                          >
                          <label
                            class="btn btn-outline-secondary"
                            :for="`none-${index}`"
                          >None</label>

                          <input
                            :id="`own-${index}`"
                            type="radio"
                            class="btn-check"
                            :name="`options-${index}`"
                            autocomplete="off"
                            :checked="role.permissions_custom && role.permissions_custom.filter(e => {
                              return e.name == permission.name && e.privacy == 'own'
                            }).length > 0"
                            @click="changePermission(permission, 'own')"
                          >
                          <label
                            class="btn btn-outline-secondary"
                            :for="`own-${index}`"
                          >Own</label>

                          <template v-if="selectedItem.length > 1">
                            <input
                              :id="`all-${index}`"
                              type="radio"
                              class="btn-check"
                              :name="`options-${index}`"
                              autocomplete="off"
                              :checked="role.permissions_custom && role.permissions_custom.filter(e => {
                                return e.name == permission.name && e.privacy == 'all'
                              }).length > 0"
                              @click="changePermission(permission, 'all')"
                            >
                            <label
                              class="btn btn-outline-secondary"
                              :for="`all-${index}`"
                            >All (admin access)</label>
                          </template>
                        </div>
                      </div>
                    </div>
                    <div class="offcanvas-footer mt-auto">
                      <a
                        title="apply actions"
                        class="btn btn-primary mb-1 d-grid w-100"
                        @click="savePermissions"
                      >Close</a>
                    </div>
                  </template>
                </ItemInfoSheet>
                <!-- <ManagePermission
                  v-for="(permission, index) in permissions"
                  :key="index"
                  :permission-name="index"
                  :item="permission"
                  :current-permissions="role.permissions"
                  :current-id="role.id"
                  :assign-to="'role'"
                  :check-all="checkAll"
                  @saveAdditionalPermissions="saveAdditionalPermissions($event)"
                /> -->
              </div>
            </div>
          </form>

        </div>

        <div class="page-actions">
          <button
            v-if="!sending"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="save" />
            <span class="ms-25 align-middle">Save and Exit</span>
          </button>
          <button
            v-else
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import ItemInfoSheet from '@/views/back/partials/offcanvas/ItemInfoSheet.vue'

export default {
  components: {
    ItemInfoSheet,
  },
  data() {
    return {
      roleId: this.$route.params.id,
      sending: false,
      errors: '',
      selectedPermissions: [],
      loading: true,
      checkAll: false,
      filters: {},
      selectedItemTitle: '',
      selectedItem: {},
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'permissions/permissions',
      role: 'roles/role',
    }),
    filterPermissions() {
      return Object.keys(this.permissions).filter(key => {
        if (this.filters.name) {
          return key.includes(this.filters.name)
        }

        return true
      }).reduce((res, key) => (res[key] = this.permissions[key], res), {})
    },
  },
  async mounted() {
    if (this.roleId) {
      await this.$store.dispatch('roles/fetchRole', this.roleId)
    } else {
      await this.$store.dispatch('roles/cleanRole')
    }
    this.loading = false
    await this.$store.dispatch('permissions/fetch')
  },
  methods: {
    changePermission(permission, privacy = null) {
      const index = this.role.permissions_custom.findIndex(e => e.id === permission.id)
      if (index >= 0) {
        if (!privacy) {
          this.role.permissions_custom.splice(index, 1)
        } else {
          this.role.permissions_custom[index] = {
            id: permission.id,
            name: permission.name,
            privacy,
          }
        }
      } else if (privacy) {
        if (!this.role.permissions_custom) {
          this.role.permissions_custom = []
        }

        this.role.permissions_custom.push({
          id: permission.id,
          name: permission.name,
          privacy,
        })
      }

      this.$forceUpdate()
    },
    openOffcanva(title, permissions) {
      this.selectedItemTitle = title
      this.selectedItem = permissions
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
    },
    async save() {
      if (!this.role.permissions_custom) {
        Vue.swal('Please, select some permissions to this role', '', 'warning')
        return
      }
      this.sending = true
      // this.role.permissions = this.selectedPermissions
      try {
        if (this.roleId) {
          await this.$store.dispatch('roles/update', { id: this.roleId, data: this.role })
        } else {
          this.role.researcher = true
          await this.$store.dispatch('roles/create', this.role)
        }
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.errors === '' && this.role.id) {
        Vue.swal('The role has been saved correctly!', '', 'success').then(() => {
          // this.$router.push({ name: 'admin.roles.index' })
        })
      } else {
        // Vue.swal
      }

      this.sending = false
    },
    savePermissions() {
      this.selectedItemTitle = ''
      this.selectedItem = {}
      this.$store.dispatch('modals/toggleInfoItemSheet', false)
    },
    saveAdditionalPermissions(permissions) {
      if (!this.role.id) {
        if (!Array.isArray(permissions)) {
          if (this.role.permissions.includes(permissions)) {
            this.role.permissions.splice(this.role.permissions.indexOf(permissions), 1)
          } else {
            this.role.permissions.push(permissions)
          }
        }
      } else {
        this.role.permissions = permissions
      }
    },
    checkAllPermissions(event) {
      event.stopPropagation()
      this.checkAll = event.target.checked
    },
  },

}
</script>
